import { SignIn } from '@clerk/react-router';
import { ContentLayout } from '~/components/admin-panel/content-layout';
import { RouteHandle } from '~/lib/route-handle';
import { getSeoMeta } from '~/lib/seo';
import { Route } from './+types/sign-in.$';

export const handle: RouteHandle = {
  breadcrumbText: 'Sign In',
};

export const meta: Route.MetaFunction = (args) => {
  return getSeoMeta(
    {
      title: 'Sign In',
    },
    // @ts-expect-error - this is a hack to pass the args to getSeoMeta
    args
  );
};

export default function SignInPage() {
  return (
    <>
      <ContentLayout title="Sign In">
        <div className="flex h-full w-full items-center justify-center">
          <SignIn path="/sign-in"></SignIn>
        </div>
      </ContentLayout>
    </>
  );
}
