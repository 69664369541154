import { ClerkProvider } from '@clerk/react-router';
import { rootAuthLoader } from '@clerk/react-router/ssr.server';
import { Provider as JotaiProvider } from 'jotai';
import Prism from 'prismjs';
import { isRouteErrorResponse, Links, Meta, Outlet, Scripts, ScrollRestoration, useRouteError } from 'react-router';
import { UnhandledError } from '~/components/page-layout/unhandled-error-page';
import { RouteHandle } from '~/lib/route-handle';
import { Route } from './+types/root';
import './global-styles/tailwind.css';
import { Layout as RootLayout } from './layouts/root-layout';

// This is a hack to ensure Prism is available in the server build
// https://github.com/remix-run/remix/discussions/8182
if (typeof globalThis.Prism === 'undefined') {
  globalThis.Prism = Prism;
}

export const handle: RouteHandle = {
  breadcrumbText: 'Home',
};

export const loader = (args: Route.LoaderArgs) => rootAuthLoader(args);

export const ErrorBoundary = () => {
  const error = useRouteError();

  if (isRouteErrorResponse(error)) {
    return (
      <UnhandledError title={error.statusText} statusCode={error.status} description={JSON.stringify(error.data)} />
    );
  } else if (error instanceof Error) {
    return <UnhandledError title="Something unexpected happened" statusCode={500} description={error.message} />;
  } else {
    return (
      <UnhandledError title="Something unexpected happened" statusCode={500} description={JSON.stringify(error)} />
    );
  }
};

export function Layout({ children }: { children: React.ReactNode }) {
  return (
    <html lang="en">
      <head>
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <meta name="robots" content="noindex, nofollow" />
        <Meta />
        <Links />
      </head>
      <body>
        {children}
        <ScrollRestoration />
        <Scripts />
      </body>
    </html>
  );
}

export default function App({ loaderData }: Route.ComponentProps) {
  return (
    <ClerkProvider loaderData={loaderData} signUpFallbackRedirectUrl="/" signInFallbackRedirectUrl="/">
      <JotaiProvider>
        <RootLayout>
          <Outlet />
        </RootLayout>
      </JotaiProvider>
    </ClerkProvider>
  );
}
