import { SignedIn, useClerk } from '@clerk/react-router';
import { Button } from '@naamdeo/shadcn-ui/components/button';
import { ScrollArea } from '@naamdeo/shadcn-ui/components/scroll-area';
import { Tooltip, TooltipTrigger, TooltipContent, TooltipProvider } from '@naamdeo/shadcn-ui/components/tooltip';
import { cn } from '@naamdeo/shadcn-ui/lib/utils';
import { Ellipsis, LogOut } from 'lucide-react';
import { useCallback } from 'react';
import { Link, useLocation } from 'react-router';
import { CollapseMenuButton } from '~/components/admin-panel/collapse-menu-button';
import { getMenuList } from '~/components/admin-panel/menu-list';

interface MenuProps {
  isOpen: boolean | undefined;
}

export function Menu({ isOpen }: MenuProps) {
  const { pathname } = useLocation();
  const menuList = getMenuList(pathname);
  const { signOut } = useClerk();
  const handleSignoutClick = useCallback(() => {
    void signOut({ redirectUrl: '/' });
  }, [signOut]);

  return (
    <ScrollArea className="h-full">
      <nav className="h-full pt-8">
        <ul className="flex h-full w-full flex-col items-start space-y-1 px-2">
          {menuList.map(({ groupLabel, menus }, index) => (
            <li className={cn('w-full', groupLabel ? 'pt-5' : '')} key={index}>
              {(isOpen && groupLabel) || isOpen === undefined ? (
                <p className="max-w-[248px] truncate px-4 pb-2 text-sm font-medium text-muted-foreground">
                  {groupLabel}
                </p>
              ) : !isOpen && isOpen !== undefined && groupLabel ? (
                <TooltipProvider>
                  <Tooltip delayDuration={100}>
                    <TooltipTrigger className="w-full">
                      <div className="flex w-full items-center justify-center">
                        <Ellipsis className="h-5 w-5" />
                      </div>
                    </TooltipTrigger>
                    <TooltipContent side="right">
                      <p>{groupLabel}</p>
                    </TooltipContent>
                  </Tooltip>
                </TooltipProvider>
              ) : (
                <p className="pb-2"></p>
              )}
              {menus.map(({ href, label, icon: Icon, active, submenus }, index) =>
                !submenus || submenus.length === 0 ? (
                  <div className="w-full" key={index}>
                    <TooltipProvider disableHoverableContent>
                      <Tooltip delayDuration={100}>
                        <TooltipTrigger asChild>
                          <Button
                            variant={active ? 'secondary' : 'ghost'}
                            className="mb-1 h-10 w-full justify-start"
                            asChild
                          >
                            <Link to={href}>
                              <span className={cn(isOpen === false ? '' : 'mr-4')}>
                                <Icon size={18} />
                              </span>
                              <p
                                className={cn(
                                  'max-w-[200px] truncate',
                                  isOpen === false ? '-translate-x-96 opacity-0' : 'translate-x-0 opacity-100'
                                )}
                              >
                                {label}
                              </p>
                            </Link>
                          </Button>
                        </TooltipTrigger>
                        {isOpen === false && <TooltipContent side="right">{label}</TooltipContent>}
                      </Tooltip>
                    </TooltipProvider>
                  </div>
                ) : (
                  <div className="w-full" key={index}>
                    <CollapseMenuButton icon={Icon} label={label} active={active} submenus={submenus} isOpen={isOpen} />
                  </div>
                )
              )}
            </li>
          ))}
          <SignedIn>
            <li className="flex w-full grow items-end">
              <TooltipProvider disableHoverableContent>
                <Tooltip delayDuration={100}>
                  <TooltipTrigger asChild>
                    <Button variant="outline" className="mt-5 h-10 w-full justify-center" onClick={handleSignoutClick}>
                      <span className={cn(isOpen === false ? '' : 'mr-4')}>
                        <LogOut size={18} />
                      </span>
                      <p className={cn('whitespace-nowrap', isOpen === false ? 'hidden opacity-0' : 'opacity-100')}>
                        Sign out
                      </p>
                    </Button>
                  </TooltipTrigger>
                  {isOpen === false && <TooltipContent side="right">Sign out</TooltipContent>}
                </Tooltip>
              </TooltipProvider>
            </li>
          </SignedIn>
        </ul>
      </nav>
    </ScrollArea>
  );
}
